.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }
  
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
    height: 200px;
    width: 200px;
  }

  .logo {
    margin-top: auto;
    height: 100px;
    width: 200px;
  }
  
  .navigation-menu {
    margin-left: auto;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    z-index:1;
  }
  
  .hamburger:hover {
    background-color: #2642af;
    color: black;

  }
  
  @media screen and (max-width: 550px) {

    .logo {
      margin-top: auto;
      align-items: center;
      padding: 0.5rem;
      height: 200px;
      width: 200px;
    }
    .hamburger {
      display: block;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      z-index:2000;
      background-color: blue;
      border-top: 1px solid black;
      display: none;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
  
    .navigation-menu li a {
      color: white;
      width: 100%;
      padding: 1.5rem 0;
    }
  
    .navigation-menu li:hover {
      background-color: #eee;
    }
  
    .navigation-menu.expanded ul {
      display: block;
    }
  }
  