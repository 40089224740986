
  section {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    /* width: 400px;
    height: 112px; */
    border-radius: 6px;
    justify-content: space-between;
  }
  .product {
    display: flex;
    flex: 1;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:rgb(9, 124, 218)
  }

  .product-checkout-button {
    display: flex;
    flex: 1;
    background-color: rgb(9, 124, 218);
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 12px;
    cursor:pointer;
  }
  